<template>
  <div class="w-full flex flex-col justify-center items-center mt-4 text-sm">
    <h2 class="w-full text-center">
      {{ language.basedOnYourAnswersYouQualifyFor }}
    </h2>
    <div
      class="w-28 h-28 border-4 border-primary rounded-full flex flex-col justify-center items-center my-4 font-secondary"
    >
      <h1 class="text-4xl font-bold">{{ eligiblePrograms.length }}</h1>
      <p class="text-sm font-bold lowercase">{{ language.programs }}</p>
    </div>
    <div
      class="flex justify-start items-center px-4 py-2 my-1 rounded-md border shadow-sm bg-white w-full"
      v-for="(program, index) in eligiblePrograms"
      :key="index"
    >
      <div
        class="flex justify-center items-center w-6 h-6 mr-4 border rounded-full border-primary bg-primary-light text-primary"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-Check"
        >
          <path d="M4 12l6 6L20 6" />
        </svg>
      </div>
      <p class="text-sm">{{ program.programName }}</p>
    </div>
  </div>
</template>

<script>
import useLanguage from "../../../composables/useLanguage";
import useQuickscreen from "../../../composables/useQuickscreen";
export default {
  setup() {
    const { eligiblePrograms } = useQuickscreen();
    const { language } = useLanguage();

    return {
      eligiblePrograms,
      language,
    };
  },
};
</script>

<style></style>
