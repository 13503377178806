<template>
  <div
    class="grid gap-x-4 px-2 sm:px-0"
    :class="showPreviousBtn ? 'grid-cols-2' : 'grid-cols-1'"
  >
    <button
      v-if="showPreviousBtn"
      class="w-full items-center py-3 rounded-md bg-gray-100 text-gray-500 hover:text-gray-800 hover:bg-gray-300 flex justify-center items-center mr-2"
      :class="loading ? 'cursor-default' : ''"
      :disabled="loading"
      @click="$emit('previous')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="ai ai-ArrowLeft mr-1"
      >
        <path d="M11 5l-7 7 7 7" />
        <path d="M4 12h16" />
      </svg>
      <slot name="previous"></slot>
    </button>
    <button
      class="w-full items-center py-3 rounded-md bg-primary shadow text-white z-10 relative next-btn"
      :class="loading ? 'cursor-default' : ''"
      :disabled="loading || !enableEnrollment"
      @click="$emit('next')"
      id="nextButton"
    >
      <span v-if="loading">
        <i class="fas fa-lg fa-circle-notch fa-spin"></i>
      </span>
      <span v-else><slot name="next"></slot></span>
    </button>
  </div>
</template>

<script>
import useQuickscreen from "../../../composables/useQuickscreen";
import useApp from "../../../composables/useApp";
import useLanguage from "../../../composables/useLanguage";
export default {
  emits: ["previous", "next"],
  props: {
    showPreviousBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    enableEnrollment: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const {
      currentStepIndex,
      steps,
      previousQuestion,
      nextQuestion,
    } = useQuickscreen();
    const { loading } = useApp();
    const { language } = useLanguage();

    return {
      loading,
      currentStepIndex,
      steps,
      previousQuestion,
      nextQuestion,
      language,
    };
  },
};
</script>

<style>
#nextButton:disabled {
  background-color: #ffe6cf;
}
</style>
