import { reactive, toRefs } from "vue";

const state = reactive({
  loading: false,
});

const setLoading = (isLoading) => {
  state.loading = isLoading;
};

export default () => {
  return {
    ...toRefs(state),
    setLoading,
  };
};
